@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.booking {
  background: -webkit-linear-gradient(to left, #f2f2f2, #ffffff);
  background: linear-gradient(to left, #f2f2f2, #ffffff);
  border: 4px dashed #2096F3;
}

.booking .booking-button {
  background: -webkit-linear-gradient(to left, #f2f2f2, #ffffff);
  background: linear-gradient(to left, #f2f2f2, #ffffff);
  margin-top: -1.85rem;
  padding-top: .75rem;
  padding-bottom: .75rem;
  border: 4px solid #2096F3;
  color: #2096F3;
}

.booking .booking-services {
  background-color: white;
}

.booking .booking-services div, .booking .booking-rut div {
  height: 100% !important;
}

.booking .booking-datepicker {
  border-color: #B0BEC5;
  border-width: 1px;
  border-style: solid;
  border-radius: 7px;
}

.booking .booking-datepicker input {
  color: #455964;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

:focus-visible {
  outline: none;
}

.booking .booking-datepicker input:focus-visible {
  outline: #607D8A auto 1px;
}

.booking .booking-datepicker button {
  color: #788F9C;
}

.booking .booking-datepicker input::placeholder {
  color: #788F9C;
  font-weight: 400;
}